var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$auth.isAuthenticated
    ? _c(
        "v-app",
        { attrs: { id: "inspire" } },
        [
          _c(
            "v-app-bar",
            { attrs: { dark: "", color: "main", app: "" } },
            [
              _c(
                "v-app-bar-nav-icon",
                {
                  on: {
                    click: function ($event) {
                      _vm.drawer = !_vm.drawer
                    },
                  },
                },
                [
                  _c("v-icon", [
                    _vm._v(_vm._s(_vm.drawer ? "mdi-backburger" : "mdi-menu")),
                  ]),
                ],
                1
              ),
              _c(
                "router-link",
                { attrs: { to: { path: "/" } } },
                [
                  _vm.tenantsWithLogos.includes(`${_vm.$auth.tenant}`)
                    ? _c("v-img", {
                        staticClass: "ml-6",
                        attrs: {
                          "max-height": "110",
                          "max-width": "110",
                          src: require(`@/assets/${_vm.$auth.tenant}_logo.png`),
                          alt: `${_vm.$auth.tenant} Logo`,
                        },
                      })
                    : _c(
                        "v-app-bar-nav-icon",
                        [
                          _c("v-icon", { attrs: { color: "#fff" } }, [
                            _vm._v("mdi-home"),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("v-spacer"),
              _vm.$auth.tenant === "awg"
                ? _c(
                    "v-menu",
                    {
                      staticClass: "mx-15",
                      attrs: {
                        "open-on-hover": "",
                        transition: "slide-x-transition",
                        bottom: "",
                        right: "",
                        "offset-y": "",
                        "close-on-content-click": false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-help-rhombus-outline"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2760996648
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-auto",
                          attrs: { width: "150", tile: "" },
                        },
                        [
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _c("v-subheader", [_vm._v("Tutorial Videos")]),
                              _c(
                                "v-list-item-group",
                                { attrs: { color: "primary" } },
                                _vm._l(_vm.videoLinks, function (link, i) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: i,
                                      on: {
                                        click: function ($event) {
                                          ;(_vm.component = "TestVideo"),
                                            (_vm.modal = true),
                                            (_vm.id = link.id)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(link.label) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "pr-0", attrs: { cols: "auto" } },
                [_c("PortalNavMenu", { attrs: { active: "adplan" } })],
                1
              ),
            ],
            1
          ),
          _c(
            "v-navigation-drawer",
            {
              attrs: { app: "", color: "toolbar" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c("v-divider"),
                        _c(
                          "div",
                          {
                            class: [!_vm.miniVariant ? "pa-2" : "pa-1"],
                            staticStyle: { height: "64px" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mt-2",
                                style: _vm.styleObject,
                                attrs: { block: "", id: "logoutBtn" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.logout()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "#fff" } }, [
                                  _vm._v("mdi-logout"),
                                ]),
                                !_vm.miniVariant
                                  ? _c("span", [_vm._v("Logout")])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2719913705
              ),
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [
              _c(
                "v-list",
                { attrs: { nav: "" } },
                _vm._l(_vm.navLinks, function (link, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      attrs: {
                        to: { path: `${link.path}` },
                        exact: "",
                        link: "",
                      },
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v(_vm._s(link.icon))])],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(_vm._s(link.text))])],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-main",
            [
              _c("AlertBar"),
              _vm.hasPortalAccess
                ? _c("router-view")
                : _c(
                    "v-alert",
                    { attrs: { type: "error", text: "", border: "left" } },
                    [
                      _vm._v(
                        " You don't have authorized permissions to access this site. "
                      ),
                    ]
                  ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    "multi-line": "",
                    bottom: "",
                    rounded: "lg",
                    height: "60",
                    transition: "slide-y-reverse-transition",
                    timeout: _vm.snackbar.timeout,
                    color: _vm.snackbar.color,
                  },
                  model: {
                    value: _vm.snackbar.showing,
                    callback: function ($$v) {
                      _vm.$set(_vm.snackbar, "showing", $$v)
                    },
                    expression: "snackbar.showing",
                  },
                },
                [
                  _c("v-row", { attrs: { dense: "", align: "center" } }, [
                    _c(
                      "span",
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(_vm._s(_vm.snackbar.icon)),
                        ]),
                        _vm._v(" " + _vm._s(_vm.snackbar.text) + " "),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "end" } },
                    [
                      _vm.snackbar.status === "error"
                        ? _c(
                            "v-btn",
                            {
                              attrs: { small: "", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.snackbar.showing = false
                                },
                              },
                            },
                            [_vm._v(" Close ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(_vm.component, {
            key: _vm.componentKey,
            tag: "Component",
            attrs: { id: _vm.id, value: _vm.modal },
            on: {
              "update:value": function ($event) {
                _vm.modal = $event
              },
            },
          }),
          _c(
            "v-footer",
            {
              staticClass: "justify-space-between",
              attrs: { color: "main", dark: "", app: "" },
            },
            [
              _c("span", { staticClass: "white--text" }, [
                _vm._v(" iPro "),
                _c("span", { staticClass: "registeredTrademark" }, [
                  _vm._v("®"),
                ]),
                _vm._v(
                  " Systems, LLC. © " + _vm._s(new Date().getFullYear()) + " "
                ),
              ]),
              _c("span", { staticClass: "white--text" }, [
                _vm._v("v. " + _vm._s(_vm.version)),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }